import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../theme/theme';
import { bodyTypography } from '../theme/typography';

const useStyles = createUseStyles(() => ({
  root: {
    backgroundColor: theme.palette.light.mono['900'],
    height: '100vh',
    maxHeight: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    overflow: 'auto',
    fontFamily: bodyTypography.fontFamily,
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
