import React from 'react';
import rehypeReact from 'rehype-react';
import { graphql } from 'gatsby';
import { createUseStyles } from 'react-jss';
import theme from '../theme/theme';
import { codeTypography } from '../theme/typography';
import MainContent from '../components/MainContent';
import Root from '../components/Root';
// import PreCode from '../components/PreCode';
import {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  HeadingFive,
  HeadingSix,
  Body,
  BodyTwo,
} from '../components/Typography';
import SEO from '../components/seo';

const useStyles = createUseStyles(() => {
  return {
    article: {
      '& blockquote': {
        background: 'hsl(0, 0%, 97%)',
        borderLeft: '4px solid grey',
        borderRadius: '4px',
        padding: '12px',
        marginLeft: 0,
        marginRight: 0,
      },
      '& code': {
        ...codeTypography,
        borderRadius: '4px',
      },
      '& code::after, & code::before': {
        content: 'none', // otherwise space is added for some reason
      },
      '& p > code': {
        padding: '0.1rem 0.3rem',
        whiteSpace: 'nowrap',
      },
      '& pre': {
        margin: `${theme.spacing.blockSpacing} 0`,
        borderRadius: '4px',
      },
    },
    infoItem: {
      marginBottom: 0,
    },
  };
});

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: HeadingOne,
    h2: HeadingTwo,
    h3: HeadingThree,
    h4: HeadingFour,
    h5: HeadingFive,
    h6: HeadingSix,
    p: Body,
    // pre: PreCode,
  },
}).Compiler;

export default ({ data }) => {
  const classes = useStyles();

  const post = data.markdownRemark;

  return (
    <Root>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <MainContent>
        <article className={classes.article}>
          <HeadingOne>{post.frontmatter.title}</HeadingOne>
          <div>
            <BodyTwo className={classes.infoItem}>
              Written by {post.frontmatter.author}
            </BodyTwo>
            <BodyTwo className={classes.infoItem}>
              Published on {post.frontmatter.published}
            </BodyTwo>
            <BodyTwo>
              Estimated reading time of {post.timeToRead} minute
              {post.timeToRead > 1 && 's'}
            </BodyTwo>
          </div>
          {renderAst(post.htmlAst)}
        </article>
      </MainContent>
    </Root>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        published(formatString: "D MMMM YYYY")
        author
      }
      htmlAst
      timeToRead
      excerpt(pruneLength: 500)
    }
  }
`;
